import React from 'react';

import Header from './header';
import Footer from './footer';

interface LayoutProps {
  children?: React.ReactNode
  location: Location
  isSiteFramed?: boolean
}

const Layout: React.FC<LayoutProps> = ({ children, location, isSiteFramed }) => {
  return (
    <>
      <Header location={location} isSiteFramed={isSiteFramed} />
      {children}
      <Footer isSiteFramed={isSiteFramed} />
    </>
  )
}

export default Layout
