

import { startCase } from 'lodash-es';
import React, { useMemo } from 'react';

import { Chip, SxProps, Theme, Tooltip } from '@mui/material';

import Amex from './icons/american_express';
import ApplePay from './icons/apple_pay';
import Diners from './icons/diners_club';
import Discover from './icons/discover';
import GooglePay from './icons/google_pay';
import Mastercard from './icons/mastercard';
import ShopPay from './icons/shop_pay';
import Visa from './icons/visa';

export type PaymentOptionType = Queries.ShopifyStorefront_CardBrand | Queries.ShopifyStorefront_DigitalWallet;

// const VisaIcon = createSvgIcon()

export const PaymentOptionIcon = ({ option, sx }: { option: PaymentOptionType, sx?: SxProps<Theme> }) => {
    const goodCase = startCase(option.toLowerCase());

    const getIcon = useMemo(() => {
        if (option === 'VISA') return <Visa sx={sx} />
        if (option === 'AMERICAN_EXPRESS') return <Amex sx={sx} />
        if (option === 'MASTERCARD') return <Mastercard sx={sx} />
        if (option === 'APPLE_PAY') return <ApplePay sx={sx} />
        if (option === 'GOOGLE_PAY') return <GooglePay sx={{ ...sx }} />
        if (option === 'DISCOVER') return <Discover sx={{ ...sx }} />
        if (option === 'DINERS_CLUB') return <Diners sx={{ ...sx }} />
        if (option === 'SHOPIFY_PAY') return <ShopPay sx={{ ...sx }} />

        return <Chip variant='outlined' color="default" label={goodCase} />
    }, [option])
    // if (option === 'VISA') return createSvgIcon(<VisaIcon />, goodCase)
    // if (option === 'VISA') return <img src={VisaIcon} style={styles} component={VisaIcon} viewBox="0 0 208 80" />

    return <Tooltip title={goodCase} placement="top">{getIcon}</Tooltip>
}