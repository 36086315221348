import { graphql, useStaticQuery } from 'gatsby';

export const useSeoMetadata = () => {
  const data: Queries.SeoMetadataQuery = useStaticQuery(graphql`
    query SeoMetadata {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `)

  return {
    site: {
      ...data.site.siteMetadata,
      image: `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`
    }
  }
}