export default {
  defaultProps: {
    disableElevation: true,
  },
  styleOverrides: {
    root: ({}) => {
      return {
        variants: [{
          props: (
            {
              ownerState
            }
          ) => ownerState.size !== 'small',

          style: {
            borderRadius: '0.75rem',
          }
        }, {
          props: (
            {
              ownerState
            }
          ) => ownerState.size == 'small',

          style: {
            borderRadius: '0.5rem',
          }
        }]
      };
    },
  }
}
