import { Link } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { last } from 'lodash-es';
import * as React from 'react';

import {
  Box, Card, CardContent, Divider, MenuItem, Select, SelectChangeEvent, Stack, Typography,
  useTheme
} from '@mui/material';

import { antiSlugify } from '../../../shared/utils';
import { AddToCart, MoneyDisplay } from '../../cart';

type ProductCardProps = {
  product: Queries.ProductDetailPageQuery['product'];
  eager: boolean
  noTitle?: boolean
  noPaper?: boolean
  actionsOnTop?: boolean
  pageType: string
  location: Location
}

type VariantSelectorProps = {
  product: Queries.ShopifyProductCardFragment;
  selectedVariantId: string;
  onVariantChange: (variantId?: string) => void;
  id: string
  fullWidth?: boolean
}

export function VariantSelector({ product, selectedVariantId, onVariantChange, id, fullWidth }: VariantSelectorProps) {
  const controlId = id
  const theme = useTheme();
  const noVariant = product.hasOnlyDefaultVariant || !product.variants || product.variants.length === 0;

  if (noVariant) {
    return <></>
  }

  const handleChange = (event: SelectChangeEvent) => {
    onVariantChange(event.target.value);
  };

  return <Select
    id={controlId}
    aria-controls={controlId}
    SelectDisplayProps={{ 'aria-controls': controlId }}
    MenuProps={{ 'aria-controls': controlId }}
    slotProps={{
      input: { 'aria-controls': controlId },
      root: { 'aria-controls': controlId }
    }}
    fullWidth={fullWidth}
    size='small'
    value={selectedVariantId}
    onChange={handleChange}
    aria-label='Variant selector'
    // displayEmpty
    style={{ backgroundColor: theme.palette.common.white }}
  >
    {
      product.variants.map((variant) => (
        <MenuItem value={variant.shopifyId} id={variant.shopifyId} key={variant.shopifyId}>{variant.title}</MenuItem>
      ))
    }
  </Select>

}

export function ProductCard({ product, eager, noTitle, noPaper, actionsOnTop }: ProductCardProps) {
  const {
    title,
    media: [firstImage],
    productType
  } = product

  const slug = `/products/${product.handle}`

  const theme = useTheme();

  const noVariant = product.hasOnlyDefaultVariant || !product.variants || product.variants.length === 0;
  const selectedVariantIdDefault = noVariant ? '' : last(product.variants).shopifyId;
  const [selectedVariantId, setSelectedVariantId] = React.useState<string>(selectedVariantIdDefault);

  // SSR
  const selectedVariant = !selectedVariantId ? product.variants.find(v => v.shopifyId === selectedVariantIdDefault) : product.variants.find(v => v.shopifyId === selectedVariantId);

  // const defaultImageHeight = 200
  // const defaultImageWidth = 200
  // let storefrontImageData = {}
  // if (storefrontImages) {
  //   const storefrontImage = storefrontImages.edges[0].node
  //   try {
  //     storefrontImageData = getShopifyImage({
  //       image: storefrontImage,
  //       layout: "fixed",
  //       width: defaultImageWidth,
  //       height: defaultImageHeight,
  //     })
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  // const hasImage = Boolean(firstImage)

  return (
    (<Card sx={[{
      height: "100%"
    }, noPaper ? {
      border: 'none'
    } : {
      border: null
    }, noPaper ? {
      background: 'none'
    } : {
      background: null
    }]} variant="outlined">
      <CardContent sx={[noPaper ? {
        padding: 0
      } : {
        padding: null
      }]}>
        <Stack direction={actionsOnTop ? "column-reverse" : "column"} gap={2}>
          {!noTitle && (
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
              <Link to={slug} style={{ textDecoration: 'none' }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.1rem", fontWeight: 700, color: "#160637" }}
                  component="div"
                >
                  {antiSlugify(productType)}
                </Typography>
              </Link>
              <Link to={slug} style={{ textDecoration: 'none' }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.1rem", fontWeight: 700, color: "#160637" }}
                  component="div"
                >
                  {title}
                </Typography>
              </Link>
              {/* <Typography
              variant="body1"
              sx={{ fontSize: "1.1rem", fontWeight: 700, color: "#160637" }}
              component="div"
            >
              {vendor}
            </Typography> */}
            </Stack>
          )}

          <Box margin="auto">
            <Link to={slug} style={{ textDecoration: 'none' }}>
              <GatsbyImage
                alt={(firstImage as ProductMedia)?.image.altText}
                // image={firstImage?.gatsbyImageData || storefrontImageData}
                image={(firstImage as any)?.image?.thumbnail?.childImageSharp?.gatsbyImageData}
                loading={eager ? "eager" : "lazy"}
              />
            </Link>
          </Box>

          {/* <Stack direction={"row"} gap={4} alignItems={"center"}> */}
          {/* <Stack
                direction="column"
                justifyContent="center"
              >
                {hasImage
                  ? (
                    <div data-name="product-image-box">
                      <GatsbyImage
                        alt={firstImage?.altText || title}
                        // image={firstImage?.gatsbyImageData || storefrontImageData}
                        image={firstImage?.gatsbyImageData}
                        loading={eager ? "eager" : "lazy"}
                      />
                    </div>
                  ) : (
                    <div style={{ height: defaultImageHeight, width: defaultImageWidth }} />
                  )
                }
              </Stack> */}


          {/* </Stack> */}

          {/* <Divider /> */}

          <Stack direction='column' gap={1}

            divider={
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ borderColor: theme.palette.grey[200] }}
              />
            }
          >
            <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
              {/* <AddToCartControls product={product} onAddToCart={onAddToCart} /> */}
              <VariantSelector id={product.id} product={product} onVariantChange={setSelectedVariantId} selectedVariantId={selectedVariantId} />
              {/* <PriceDisplay variant={selectedVariant} /> */}

              <MoneyDisplay money={selectedVariant.presentmentPrices[0].price} nonDiscounted={selectedVariant.presentmentPrices[0].compareAtPrice} />
              {/* <div>{minPrice} - {maxPrice}</div> */}
              {/* <Button href={slug} variant='outlined'>View</Button> */}

            </Stack>
            {/* <AddToCart location={location} quantity={1} available={selectedVariant.inventoryQuantity > 0} variantId={selectedVariantId} product={product} pageType={pageType} /> */}
          </Stack>
        </Stack>
      </CardContent>
    </Card>)
  );
}

export type ProductMedia = {
  shopifyId: string
  image: {
    altText?: string | null;
    gatsbyImageData?: IGatsbyImageData;
  }
}

export type PriceRange = {
  minVariantPrice: PriceInfo
  maxVariantPrice: PriceInfo
}

export type PriceInfo = {
  amount: string
  currencyCode: string
}