import React from 'react';

import { Stack, Typography } from '@mui/material';

import { NavItemGroupProps } from './nav-item-group';
import { NavLink } from './ui';

export default function NavItemGroupStatic({
  name,
  navItems,
  ...other
}: NavItemGroupProps) {
  return (
    <Stack gap={{ xs: 0, md: 2 }}>
      <Typography sx={{ fontSize: "1rem", fontWeight: 700, color: "#160637" }} {...other.contentfulPreviewProps}>
        {name}
      </Typography>

      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap={{ xs: 0, md: 2 }}
      >
        {navItems.map((navItem) => {
          return (
            <NavLink
              key={navItem.id}
              to={navItem.href}
              sx={{
                p: "2px",
                color: "inherit",
                m: 0,
                width: { xs: "100%", sm: "100%", md: "inherit" }, // SEO
                minHeight: { xs: "48px", sm: "48px", md: "inherit" }, // SEO
                fontSize: "0.85rem",
                textAlign: "left",
                minWidth: "0px",
                borderRadius: "2px",
                justifyContent: "left",
              }}
              {...navItem.contentfulPreviewProps}
            >
              {navItem.text}
            </NavLink>
          )
        })}
      </Stack>
    </Stack>
  )
}