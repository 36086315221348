import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import wrapPageElements from './wrap-page-element';
import WrapWithProviders from './wrap-with-providers';
// import { CacheProvider } from '@emotion/react'


// import { myCache } from './emotion-cache'

import type { PersonIdentifiers } from '@seeka-labs/converge';
// import React from 'react';

export const onClientEntry = () => {
    // Function to dynamically load a script
    const loadScript = (src, id, onLoad?) => {
        if (document.getElementById(id)) return; // Prevent reloading the same script
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.async = true;
        script.onload = onLoad;
        document.body.appendChild(script);
    };

    const setKlaviyoSettings = () => {
        const klav = (window as any).klaviyo;
        if (!klav || !klav.cookieDomain) {
            setTimeout(() => {
                setKlaviyoSettings()
            }, 1000)
        }
        else {
            klav?.cookieDomain('blootropic.com').then((r) => { })
                .catch((e) => {
                    console.error(e);
                });
        }
    }

    // Event listener to load scripts after user interaction
    const handleInteraction = () => {
        // Load your specific scripts here
        loadScript("https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SjPHyt", "klaviyo", () => {
            setKlaviyoSettings()
        });

        if (process.env.GATSBY_SHOPIFY_INBOX_CHAT_SCRIPTURL) {
            loadScript(process.env.GATSBY_SHOPIFY_INBOX_CHAT_SCRIPTURL, "shopify-inbox-chat");
        }

        // Remove listeners after loading to avoid redundant calls
        window.removeEventListener("scroll", handleInteraction);
        window.removeEventListener("click", handleInteraction);
        window.removeEventListener("keydown", handleInteraction);
    };

    // Add event listeners for user interaction
    window.addEventListener("scroll", handleInteraction);
    window.addEventListener("click", handleInteraction);
    window.addEventListener("keydown", handleInteraction);
};

const getNewRelicBrowserApi = (): typeof newrelic | null => {
    if ((window as any).newrelic) return (window as any).newrelic;

    return null
}

export const onInitialClientRender = () => {
    const isSsr = typeof window === 'undefined';

    if (!isSsr) {
        // Klaviyo
        // const klav = (window as any).klaviyo;
        // klav?.cookieDomain('blootropic.com').then((r) => { })
        //     .catch((e) => {
        //         console.error(e);
        //     });

        // New relic user ID and release info
        const nr = getNewRelicBrowserApi();
        if (nr) {
            console.debug('New Relic Browser API found');
            if (process.env.GATSBY_NETLIFY_BUILD_ID && process.env.GATSBY_NETLIFY_CONTEXT) {
                nr.addRelease(process.env.GATSBY_NETLIFY_CONTEXT, process.env.GATSBY_NETLIFY_BUILD_ID)
                console.debug('New Relic release added');
            }
            window.addEventListener("converge.identity.changed", (ev) => {
                const seekaIdentity = (ev as any).detail?.identifiers as PersonIdentifiers | undefined | null;
                const seekaPId = seekaIdentity?.seekaPId;

                if (seekaPId)
                    nr.setUserId(seekaPId)
            })
        }
    }
}

export const wrapPageElement = wrapPageElements

export const wrapRootElement = WrapWithProviders

// export const wrapRootElement = (props) => (
//     <CacheProvider value={myCache}>
//         <WrapWithProviders {...props} />
//     </CacheProvider>
// )