import { css } from "@emotion/react";


export const printOnly = css({
    '@media print': {
        display: 'inherit',
    },
    '@media screen': {
        display: 'none',
    }
})