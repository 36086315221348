import * as React from 'react';
import { Box } from '@mui/material';
import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

type Props = {
    metafields: Queries.ProductDetailPageQuery['shopifyStorefront']['products']['nodes'][0]['metafields']
}

type ProductHighlightProps = {
    content: string
    title: string
    icon?: string
    index: number
}

const ProductHighlight = ({ content, title, index }: ProductHighlightProps) => {
    return (
        <ListItem sx={{ paddingLeft: 0, paddingRight: 0, marginBottom: 0, paddingBottom: 0 }}>
            {/* <ListItemAvatar>
                <Avatar>
                    <Check htmlColor={theme.palette.success.main} />
                </Avatar>
            </ListItemAvatar> */}
            <ListItemText primaryTypographyProps={{ component: 'div' }} secondaryTypographyProps={{ component: 'div' }} primary={<strong>{title}</strong>} secondary={<div
                className="html"
                dangerouslySetInnerHTML={{
                    __html: convertSchemaToHtml(content),
                }}
            />} />
        </ListItem>
    )
}

export const ProductHighlights = ({ metafields }: Props) => {
    const highlights = metafields.filter(metafield => metafield.key == 'highlights' && metafield.references).flatMap(e => e.references).flatMap(e => e.nodes).map(e => {
        const fields = (e as any).fields as any[];
        return {
            title: fields.find(f => f.key === 'title')?.value,
            icon: fields.find(f => f.key === 'icon')?.value,
            content: fields.find(f => f.key === 'content')?.value
        }
    })
    if (!highlights || highlights.length === 0) return <></>

    return (
        <Box>
            <List sx={{ width: '100%' }}>
                {highlights.map((highlights, index) => {
                    return <ProductHighlight key={index} content={highlights.content} title={highlights.title} index={index} />
                })}
            </List>
        </Box>
    )
}