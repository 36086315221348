import isAbsoluteUrl from 'is-absolute-url';
import React from 'react';


import thumbnailImage from '../assets/img/blootropic-logo-colored-seo-thumbnail.png';
import defaultImage from '../assets/img/blootropic-logo-colored-seo.png';

import orgLogo from '../assets/img/logo-colored-seo-logoicon.png';
import orgImage from '../assets/img/logo-colored-seo-logotext.png';

import { useSeoMetadata } from '../shared/hooks/useSeoMetadata';

type HeadComponentProps = {
  title?: string
  description?: string
  pathname: string
  image?: { url?: string; publicURL?: string }
  children?: React.ReactNode
}

// export type PrefetchElements = {
//   images?: ContentfulAsset[]
// }

export default function SeoHead({
  title,
  description,
  pathname,
  image,
  children,
}: HeadComponentProps) {
  const seoMetadata = useSeoMetadata();

  const seo = {
    title: title ? `${seoMetadata.site.title} - ${title}` : seoMetadata.site.title,
    description: description || seoMetadata.site.description,
    image: image?.url || image?.publicURL || (defaultImage as string),
    url: `${seoMetadata.site.siteUrl}${pathname || ``}`
  }
  if (!isAbsoluteUrl(seo.image)) {
    // remove leading slash
    seo.image = seo.image.replace(/^\//, '')
    seo.image = `${seoMetadata.site.siteUrl}/${seo.image}`
  }

  return (
    <>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="google-adsense-account" content="ca-pub-7404596419417719" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:site_name" content={seoMetadata.site.title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={seo.description} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />

      <meta name="thumbnail" content={seoMetadata.site.siteUrl + thumbnailImage} />
      <link rel="canonical" href={seo.url} />
      <link rel="icon" href={seoMetadata.site.siteUrl + thumbnailImage} type="image/x-icon" />
      <meta name="viewport" content="initial-scale=1, width=device-width" />

      <script id="klaviyo-onsite" type="text/javascript">
        {`!function(){if(!window.klaviyo){window._klOnsite=window._klOnsite||[];try{window.klaviyo=new Proxy({},{get:function(n,i){return"push"===i?function(){var n;(n=window._klOnsite).push.apply(n,arguments)}:function(){for(var n=arguments.length,o=new Array(n),w=0;w<n;w++)o[w]=arguments[w];var t="function"==typeof o[o.length-1]?o.pop():void 0,e=new Promise((function(n){window._klOnsite.push([i].concat(o,[function(i){t&&t(i),n(i)}]))}));return e}}})}catch(n){window.klaviyo=window.klaviyo||[],window.klaviyo.push=function(){var n;(n=window._klOnsite).push.apply(n,arguments)}}}}();`}
      </script>

      <link
        key="precon-nrjs"
        rel="preconnect"
        crossOrigin='anonymous'
        href="https://js-agent.newrelic.com"
      />
      <link
        key="dns-nrjs"
        rel="dns-prefetch"
        href="https://js-agent.newrelic.com"
      />
      <link
        key="precon-ctfassets"
        rel="preconnect"
        crossOrigin='anonymous'
        href="https://images.ctfassets.net"
      />
      <link
        key="dns-ctfassets"
        rel="dns-prefetch"
        href="https://images.ctfassets.net"
      />

      <script type="application/ld+json" id="schema-org" key="schema-org">
        {`
          {
              "@context": "https://schema.org",
              "@type": "Organization",
              "image": "${seoMetadata.site.siteUrl}${orgImage}",
              "logo": "${seoMetadata.site.siteUrl}${orgLogo}",
              "name": "${seoMetadata.site.title}",
              "url": "${seoMetadata.site.siteUrl}",
              "email": "support@blootropic.com",
              "legalName": "Blootropic",
              "description": "${seoMetadata.site.description}"
          }
        `}
      </script>

      <meta name="fragment" content="!" />

      {children}
    </>
  )
}
