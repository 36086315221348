import { css } from '@emotion/react';

export type DynamicHeroHeightProps = {
  breakpointHeight?: number;
  minHeroHeight?: number;
  autoHeight?: boolean;
}

export const dynamicHeroHeight = ({ breakpointHeight, minHeroHeight, autoHeight }: DynamicHeroHeightProps) =>
  css`
  @media screen and (min-height: ${autoHeight ? 0 : breakpointHeight || 700}px) {    
    height: ${minHeroHeight || 700}px;
  }
  @media screen and (max-height: ${autoHeight ? 10000 : breakpointHeight || 700}px) {    
    height: ${autoHeight ? '100%' : '100vh'};
  }
`