import { graphql, useStaticQuery } from 'gatsby';
import { uniq } from 'lodash-es';

import { useCart } from '@shopify/hydrogen-react';
import { MoneyV2 } from '@shopify/hydrogen-react/storefront-api-types';

import { useMemo } from 'react';
import type { ExtendedCart } from '../../pages/cart';

export enum AutomaticDiscountType {
  Basic = 'ShopifyAdmin_DiscountAutomaticBasic',
  FreeShipping = 'ShopifyAdmin_DiscountAutomaticFreeShipping'
}

export enum AutomaticDiscountMinimumRequirementType {
  Quantity = 'ShopifyAdmin_DiscountMinimumQuantity',
  Subtotal = 'ShopifyAdmin_DiscountMinimumSubtotal'
}

export interface AutomaticDiscount {
  title: string
  type: AutomaticDiscountType
  minimumRequirement: AutomaticDiscountMinimumRequirement
  status: AutomaticDiscountStatus
  startsAt?: string
  endsAt?: string
  customerGets?: Queries.ShopifyAdmin_DiscountAutomaticBasic['customerGets']
}

export interface AutomaticDiscountMinimumRequirement {
  type: AutomaticDiscountMinimumRequirementType
  greaterThanOrEqualToQuantity?: string
  greaterThanOrEqualToSubtotal?: MoneyV2
}

export enum AutomaticDiscountStatus {
  Active = 'ACTIVE',
  Scheduled = 'SCHEDULED',
  Expired = 'EXPIRED'
}

export const useAutomaticDiscountList = () => {
  const discountQuery: Queries.AutomaticDiscountsQuery = useStaticQuery(graphql`
        query AutomaticDiscounts {
            shopifyAdmin {
                automaticDiscountNodes(first: 10, sortKey: CREATED_AT) {
                  nodes {
                    automaticDiscount {
                      type: __typename
                      ... on ShopifyAdmin_DiscountAutomaticFreeShipping {
                        title
                        status
                        startsAt
                        endsAt
                        freeShippingDiscountClass: discountClass
                        minimumRequirement {
                          type: __typename
                          ... on ShopifyAdmin_DiscountMinimumQuantity {
                            greaterThanOrEqualToQuantity
                          }
                          ... on ShopifyAdmin_DiscountMinimumSubtotal {
                            greaterThanOrEqualToSubtotal {
                              amount
                              currencyCode
                            }
                          }
                        }
                      }
                      ... on ShopifyAdmin_DiscountAutomaticBasic {
                        title
                        status
                        startsAt
                        endsAt
                        customerGets {
                          items {
                            ... on ShopifyAdmin_AllDiscountItems {
                              allItems
                              
                            }
                          }
                          value {
                            ...on ShopifyAdmin_DiscountPercentage {
                              percentage                              
                            }
                          }
                        }                        
                        basicDiscountClass: discountClass
                        minimumRequirement {
                          type: __typename
                          ... on ShopifyAdmin_DiscountMinimumQuantity {
                            greaterThanOrEqualToQuantity
                          }
                          ... on ShopifyAdmin_DiscountMinimumSubtotal {
                            greaterThanOrEqualToSubtotal {
                              amount
                              currencyCode
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }           
        }
        `)

  const { cost, discountAllocations } = useCart() as ExtendedCart;

  const allAutomaticDiscounts = useMemo(() => {
    return ((discountQuery?.shopifyAdmin?.automaticDiscountNodes?.nodes || []).map(e => e.automaticDiscount) as AutomaticDiscount[]).filter(e => e.status === AutomaticDiscountStatus.Active).sort((a, b) => b.type.localeCompare(a.type));
  }, [cost, discountAllocations])

  const automaticDiscounts = !cost ? [] : allAutomaticDiscounts.filter(e => {
    if (e.type === AutomaticDiscountType.Basic) return true;

    if (e.type === AutomaticDiscountType.FreeShipping && Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount) > 0) {
      const minSubtotal = Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount || 0)
      const currentCartSubtotal = (Number(cost.subtotalAmount.amount) || 0);
      if (minSubtotal > 0 && currentCartSubtotal <= minSubtotal) {
        return true
      }
    }
    return false;
  })

  const appliedDiscounts = !cost ? [] : (allAutomaticDiscounts || []).map((e) => {
    if (e.type === AutomaticDiscountType.Basic && discountAllocations?.find((d) => (d as any).title as string === e.title)) {
      return e;
    };

    if (e.type === AutomaticDiscountType.FreeShipping && Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount) > 0) {
      const minSubtotal = Number(e.minimumRequirement.greaterThanOrEqualToSubtotal?.amount || 0)
      const currentCartSubtotal = (Number(cost.subtotalAmount.amount) || 0);
      if (minSubtotal > 0 && currentCartSubtotal >= minSubtotal) {
        return e
      }
    }

    return null;
  }).filter(e => Boolean(e))

  const appliedDiscountTitles = uniq(appliedDiscounts.map(e => e.title)) as string[]
  const notYetAppliedDiscounts = (automaticDiscounts || []).filter(e => !appliedDiscountTitles.includes(e.title)).filter(e => {
    if (!appliedDiscounts || appliedDiscounts.length === 0) return true;
    if (e.customerGets && e.customerGets.value && (e.customerGets.value as Queries.ShopifyAdmin_DiscountPercentage).percentage > 0) {
      // If appliedDiscounts contains a discount with a higher percentage, don't show this discount
      const thisPercentage = (e.customerGets.value as Queries.ShopifyAdmin_DiscountPercentage).percentage;
      if (appliedDiscounts.find(d => (d.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage)?.percentage && (d.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage)?.percentage > thisPercentage)) {
        return false;
      }
    }
    return true
  })

  const defaultDiscounts = allAutomaticDiscounts.filter(e => e.status === 'ACTIVE' && e.type === "ShopifyAdmin_DiscountAutomaticBasic" && (e.minimumRequirement == null || parseInt(e.minimumRequirement.greaterThanOrEqualToQuantity) === 1));

  const getQuantityDiscounts = (quantity: number) => allAutomaticDiscounts.filter(d => d.status === 'ACTIVE' && d.type === "ShopifyAdmin_DiscountAutomaticBasic" && d.minimumRequirement && quantity >= parseInt(d.minimumRequirement.greaterThanOrEqualToQuantity));
  const getDefaultQuantityDiscount = (quantity: number) => {
    const quantityDiscounts = getQuantityDiscounts(quantity)
    return quantityDiscounts.length > 0 ? quantityDiscounts.sort(e => (e.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage).percentage).slice(0, 1)[0] : null
  }

  const defaultDiscount = defaultDiscounts.sort(e => (e.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage).percentage).slice(0, 1)[0];

  const discountForQuantity = (quantity: number) => {
    const defaultQuantityDiscount = getDefaultQuantityDiscount(quantity)
    const defaultValue = defaultDiscount?.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage;
    const defaultQuantityValue = defaultQuantityDiscount?.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage;

    if (defaultQuantityValue && defaultValue) {
      return defaultQuantityValue.percentage > defaultValue.percentage ? defaultQuantityValue : defaultValue;
    } else if (defaultQuantityValue) {
      return defaultQuantityValue;
    }
    else if (defaultValue) {
      return defaultValue;
    }
    else {
      return null;
    }
  }

  return {
    appliedDiscounts: appliedDiscounts,
    notAppliedDiscounts: notYetAppliedDiscounts,
    activeDiscounts: allAutomaticDiscounts,
    hasFreeShipping: Boolean(appliedDiscounts.find(e => e.type === AutomaticDiscountType.FreeShipping)),
    quantityDiscounts: getQuantityDiscounts,
    defaultDiscounts,
    defaultQuantityDiscount: getDefaultQuantityDiscount,
    defaultDiscount,
    isBigDefaultDiscount: defaultDiscount && (defaultDiscount.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage).percentage >= 0.25,
    defaultDiscountPercent: defaultDiscount && (defaultDiscount.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage).percentage >= 0.25 ? (defaultDiscount.customerGets?.value as Queries.ShopifyAdmin_DiscountPercentage).percentage * 100 : null,
    discountForQuantity,
    discountPriceForPrice: (quantity: number, originalPrice: MoneyV2 | Queries.ShopifyMoneyV2): MoneyV2 => {
      const theDiscount = discountForQuantity(quantity);
      const discountPercent = (theDiscount?.percentage * 100) || 0;

      return {
        ...originalPrice,
        amount: (discountPercent ? (parseFloat(originalPrice.amount.toString()) * (1 - discountPercent / 100)) : parseFloat(originalPrice.amount.toString())).toFixed(2)
      }
    }
  }
}