import GlobalStyles from "@mui/material/GlobalStyles";
import { WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby";
import React from "react";
import Layout from "./src/components/layout";

const globalStyles = <GlobalStyles
  styles={(theme) => ({
    '.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg': {
      height: '60px!important',
      width: '20px!important',
      stroke: 'black'
    },
    '.image-gallery-icon': {
      filter: 'none!important',
    },
    '.image-gallery-thumbnail, .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:focus-visible, .image-gallery-thumbnail:hover': {
      border: 'none!important',
      transition: 'none!important'
    },
    '.image-gallery-thumbnail-image:focus-visible': {
      outline: 'none!important',
      border: 'none!important',
      transition: 'none!important'
    },
    '.html p': {
      margin: '0',
      marginBottom: '10px'
    },
    'html': {
      overflowX: 'hidden'
    },
    'body': {
      overflowX: 'hidden'
    },
    '.snackbar-container-root .notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main
    },
    '.MuiAccordionDetails-root p': {
      whiteSpace: 'pre-wrap'
    },
    '.MuiAccordionSummary-content': {
      fontWeight: 'bold'
    }
  })}
/>

export default ({ element, props }: WrapPageElementBrowserArgs | WrapPageElementNodeArgs) => {
  const isSiteFramed = false;

  return (
    <>
      {globalStyles}
      <Layout location={props.location} isSiteFramed={isSiteFramed}>
        {element}
      </Layout>
    </>
  )
}
