import { graphql, PageProps } from 'gatsby';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useEffect, useMemo, useState } from 'react';

import { Box, Container, Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid2'; // Grid version 2
import { AnalyticsPageType } from '@shopify/hydrogen-react';
import { last } from 'lodash-es';

import SeoHead from '../../components/head';
import { HorizontalSlantContainer } from '../../components/horizontal-slant-container';
import { VariantSelector } from '../../components/products/card';
import { removeShopifyIdPath, useAnalytics } from '../../shared/analytics';
import { headerSlantContainerNoContent } from '../../shared/styles/headerSlantContainer';
// import { antiSlugify } from '../../shared/utils';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { AddToCart, MoneyDisplay } from '../../components/cart';
import { NumericInput } from '../../components/controls/numeric-input';
import { ProductHighlights } from '../../components/products/highlights';
import { ProductTabs } from '../../components/products/tabs';
import { useAutomaticDiscountList } from '../../shared/hooks/useAutomaticDiscountList';
import { useSeoMetadata } from '../../shared/hooks/useSeoMetadata';
import { shopifyImageToJsonLd, shopifyProductToJsonLd } from '../../shared/models/structuredData';
import { FaqList } from '../../templates/faq/faqList';

type ProductDetailPageServerProps = {
  isSiteFramed: boolean;
  variant: string | null;
  origin: string;
}
export const indexOfFirstProductImage = 1;

const cardBorderStyles = (theme: Theme, borderWidth?: number) => {
  return {
    borderStyle: 'solid', borderWidth: (borderWidth || 1) + 'px', borderColor: theme.palette.grey[400], borderRadius: '10px'
  }
}

// Commented out below as it was causing long load times on netlify
// export const getServerData: GetServerData<ProductDetailPageServerProps> = async ({ query, url, headers, params }) => {
//   const variant = (query?.variant as string) || null;
//   console.log('heree ProductDetailPageServerProps', headers, query, url, params, variant)
//   return {
//     props: {
//       isSiteFramed: query?.iframe === 'true',
//       variant: variant,
//       origin: (headers.get(':authority:') || headers.get('host')) as string
//     },
//   };
// }

type AddToCartCardProps = AddToCartMultiProps & {
  quantity: number
}

type AddToCartMultiProps = {
  product: Queries.ProductDetailPageQuery['product']
  variantId: string
  quantityDescription: string
  selectedQuantity: number
  setSelectedQuantity?: (quantity: number) => void
}

const AddToCartCard = ({ product, variantId, quantity, quantityDescription, selectedQuantity, setSelectedQuantity }: AddToCartCardProps) => {
  const theme = useTheme();

  const defaultVariant = last(product.variants);
  const selectedVariant = variantId ? product.variants.find(v => v.shopifyId === variantId) : defaultVariant

  const isSelected = selectedQuantity === quantity;
  const borderStyles = cardBorderStyles(theme, isSelected ? 3 : 1);

  // const tag = quantity === 2 ? 'Most popular' : (quantity === 3 ? 'Best value' : null);
  const discounts = useAutomaticDiscountList();

  // get top 1 defaultDiscounts, sort by ascendening percentage and get the top one
  // const defaultQuantityDiscount = discounts.defaultQuantityDiscount(quantity);
  // const defaultDiscount = discounts.defaultDiscount
  // const theDiscount = discounts.discountForQuantity(quantity);

  // console.log('heree theDiscount', {
  //   quantity,
  //   activeDiscounts: discounts.activeDiscounts, theDiscount, defaultQuantityDiscount, defaultDiscount
  // })

  // const discountPercent = theDiscount?.percentage * 100;

  const discountedPrice = discounts.discountPriceForPrice(quantity, selectedVariant.presentmentPrices[0].price);

  return (
    <Box p={1} sx={{ ...borderStyles, width: '100%', cursor: 'pointer' }} onClick={() => setSelectedQuantity(quantity)}>
      <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} gap={{ xs: 1, sm: 1, md: 2 }} alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
        <Stack direction='row' gap={2} alignItems={'center'} justifyContent={'flex-start'}>
          {isSelected ? <RadioButtonChecked color='primary' /> : <RadioButtonUnchecked />}
          <Typography variant='h4'>{quantity} {quantityDescription}{quantity > 1 ? 's' : ''}</Typography>
          {/* {tag && <Chip label={tag} variant="filled" color='success' />} */}
        </Stack>

        <MoneyDisplay money={discountedPrice} invertSaveDirection quantity={quantity} nonDiscounted={selectedVariant.presentmentPrices[0].price} showSavePill savePillAsValue direction='row' />
      </Stack>
    </Box>
  )
}

const AddToCartMulti = ({ product, variantId, quantityDescription, selectedQuantity, setSelectedQuantity }: AddToCartMultiProps) => {

  return (
    <Stack direction='column' gap={{ xs: 1, sm: 1, md: 2 }} alignItems={'center'} justifyContent={'center'} sx={{ width: '100%' }}>
      <AddToCartCard product={product} variantId={variantId} quantity={3} quantityDescription={quantityDescription} selectedQuantity={selectedQuantity} setSelectedQuantity={setSelectedQuantity} />
      <AddToCartCard product={product} variantId={variantId} quantity={2} quantityDescription={quantityDescription} selectedQuantity={selectedQuantity} setSelectedQuantity={setSelectedQuantity} />
      <AddToCartCard product={product} variantId={variantId} quantity={1} quantityDescription={quantityDescription} selectedQuantity={selectedQuantity} setSelectedQuantity={setSelectedQuantity} />
    </Stack>
  )

}

export default function ProductDetailPage({ data, location }: PageProps<Queries.ProductDetailPageQuery, object, object, ProductDetailPageServerProps>) {
  // console.log('heree ProductDetailPage', data.product, serverData)

  // console.log('heree ProductDetailPage', data, pageContext)
  // const theme = useTheme();

  const headerSlantContainerNoContentStyles = headerSlantContainerNoContent();
  // const { isSiteFramed } = (serverData as any) || { isSiteFramed: false };
  const { isSiteFramed } = { isSiteFramed: false };

  const { product } = data

  const storefrontProduct = data.shopifyStorefront.products.nodes[0];
  const analytics = useAnalytics();
  useEffect(() => {
    analytics.trackPageView(AnalyticsPageType.product, {
      collectionHandle: productType,
      products: [
        {
          ...product
        }
      ]
    });
  }, [])

  const {
    title,
    media,
    productType,
    descriptionHtml
  } = data.product

  // const borderStyles = cardBorderStyles(theme);

  // const theme = useTheme();

  // const urlParams = new URLSearchParams(location.search);
  // console.log('heree location', uri, path, params, location)
  // console.log('serverData', serverData)
  const defaultQty = 2;

  const [qtyToAdd, setQtyToAdd] = useState(defaultQty);
  const noVariant = product.hasOnlyDefaultVariant || !product.variants || product.variants.length === 0;
  // const urlVariantId = new URLSearchParams(location.search).get('variant');
  // const urlVariant = !noVariant ? product.variants.find(v => v.shopifyId.split('/').splice(-1)[0].toString() === urlVariantId) : null
  // const defaultVariantId = noVariant ? '' : (urlVariant ? urlVariant.shopifyId : last(product.variants).shopifyId)
  const defaultVariant: Queries.ProductDetailPageQuery['product']['variants'][0] = last(product.variants);
  const [selectedVariantId, setSelectedVariantId] = React.useState<string>(noVariant ? null : defaultVariant.shopifyId);

  const discounts = useAutomaticDiscountList();

  useEffect(() => {
    if (typeof window !== 'undefined' && !noVariant) {
      const urlVariantId = new URLSearchParams(location.search).get('variant');
      if (urlVariantId) {
        const urlVariant = !noVariant ? product.variants.find(v => v.shopifyId.split('/').splice(-1)[0].toString() === urlVariantId) : null
        if (urlVariant) {
          setSelectedVariantId(urlVariant.shopifyId)
        }
      }
    }
  }, []);

  const selectedVariant = selectedVariantId ? product.variants.find(v => v.shopifyId === selectedVariantId) : defaultVariant

  const discountedPrice = discounts.discountPriceForPrice(1, selectedVariant.presentmentPrices[0].price);

  // console.log('heree selectedVariant', selectedVariant, selectedVariantId, defaultVariantId)

  //localFile.childImageSharp.gatsbyImageData
  const images = useMemo<ReactImageGalleryItem[]>(() => {
    return media.map((image, index): ReactImageGalleryItem => ({
      original: ((image as any).image.fullSize.childImageSharp.gatsbyImageData as IGatsbyImageData).images.fallback.src,
      // sizes: ((image as any).image.fullSize.childImageSharp.gatsbyImageData as IGatsbyImageData).images.fallback.sizes,
      originalAlt: (image as any).image.altText,
      // originalHeight: ((image as any).image.fullSize.childImageSharp.gatsbyImageData as IGatsbyImageData).height,
      // originalWidth: ((image as any).image.fullSize.childImageSharp.gatsbyImageData as IGatsbyImageData).width,
      // srcSet: ((image as any).image.fullSize.childImageSharp.gatsbyImageData as IGatsbyImageData).images.fallback.srcSet,
      thumbnail: ((image as any).image.thumbnail.childImageSharp.gatsbyImageData as IGatsbyImageData).images.fallback.src,
      thumbnailAlt: (image as any).image.altText,
      loading: index === 0 ? 'eager' : 'lazy'
    }))
  }, []);

  return (<>
    {!isSiteFramed && <HorizontalSlantContainer id={'product_' + product.shopifyId} autoHeight>
      <Container sx={headerSlantContainerNoContentStyles}></Container>
    </HorizontalSlantContainer>}
    <Container sx={{ pt: { xs: 2, sm: 2, md: 6 } }}>
      <Grid container rowSpacing={2} columnSpacing={4}>

        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 6
          }}>
          {images.length > 0 && <ImageGallery items={images} showNav={true} showPlayButton={false} showFullscreenButton={false} />}
        </Grid>

        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 6
          }}>
          <Stack direction='column' gap={2}>
            <Typography variant='h1'>{title}</Typography>

            <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} gap={{ xs: 2, sm: 3, md: 8 }} alignItems={{ xs: 'flex-start', sm: 'flex-start', md: 'center' }}>
              {selectedVariant.title !== 'Default Title' && <Typography variant='h4'>{selectedVariant.title}</Typography>}
              <MoneyDisplay money={discountedPrice} nonDiscounted={selectedVariant.presentmentPrices[0].price} direction='row' showSavePill />
            </Stack>

            <Box
              dangerouslySetInnerHTML={{
                __html: descriptionHtml,
              }}
            />
          </Stack>

          <Box mb={2}>
            <ProductHighlights metafields={storefrontProduct.metafields} />
          </Box>

          <Stack direction='column' gap={2}>
            {/* <Box p={2} sx={{ ...borderStyles }}> */}
            <Box>
              <Stack direction='column' gap={2}>
                <AddToCartMulti product={product} variantId={selectedVariantId} quantityDescription='Bottle' selectedQuantity={qtyToAdd} setSelectedQuantity={setQtyToAdd} />
                <Stack direction='row' gap={2} sx={{ width: '100%' }} alignItems={'center'} justifyContent={'center'}>
                  <NumericInput
                    id='product-quantity'
                    value={qtyToAdd || defaultQty}
                    includeZero={false}
                    variant="outlined"
                    size="small"
                    onChange={(v) => setQtyToAdd(v)}
                  />
                  {/* <Typography variant='body1' sx={{ width: '75px' }} component={'div'}>{(qtyToAdd ? qtyToAdd : defaultQty) === 1 ? 'bottle of' : 'bottles of'}</Typography>                   */}

                  {!isSiteFramed && <VariantSelector fullWidth id={product.id} product={product} onVariantChange={setSelectedVariantId} selectedVariantId={selectedVariantId} />}
                </Stack>

                {!isSiteFramed && <AddToCart pathname={location.pathname} quantity={qtyToAdd || defaultQty} available={selectedVariant.inventoryQuantity > 0} variantId={selectedVariantId} product={product} pageType={AnalyticsPageType.product} />}
                {/* <MoneyDisplay money={selectedVariant.presentmentPrices[0].price} nonDiscounted={selectedVariant.presentmentPrices[0].compareAtPrice} direction='row' /> */}
              </Stack>
            </Box>
            <ProductTabs metafields={storefrontProduct.metafields} />
          </Stack>
        </Grid>

        <Grid
          size={{
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12
          }}>
          <Box mt={3}>
            <Paper elevation={0}>
              <Stack spacing={2} justifyContent={'center'} alignItems={'center'} pt={2}>
                <Box>
                  <Typography variant="h3" gutterBottom>
                    Frequently Asked Questions
                  </Typography>
                </Box>

                <FaqList />
              </Stack>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container >
  </>);
}

export const Head = ({ data: { product, shopifyAdmin }, location }: PageProps<Queries.ProductDetailPageQuery, any, any, ProductDetailPageServerProps>) => {
  const {
    title,
    description,
    seo1x1Image,
    seo16x9Image,
    seo4x3Image,
    media
  } = product

  const shopifyAdminProduct = shopifyAdmin.products.nodes[0];
  const metafields = shopifyAdminProduct.metafields;
  const seoMetadata = useSeoMetadata();
  const seoDescription = metafields.nodes?.find(metafield => metafield.key == 'description_tag')?.value || description;

  // console.log('heree Head', media)

  const jsonLdImages = [];
  if (seo1x1Image) {
    jsonLdImages.push(shopifyImageToJsonLd(seo1x1Image.preview.image));
  }
  if (seo16x9Image) {
    jsonLdImages.push(shopifyImageToJsonLd(seo16x9Image.preview.image));
  }
  if (seo4x3Image) {
    jsonLdImages.push(shopifyImageToJsonLd(seo4x3Image.preview.image));
  }

  let metaImageUrl: string | null = null;

  if (jsonLdImages.length === 0 && media && media.length > 0) {
    const img: any = media[indexOfFirstProductImage];
    const gatsbyImg = ((img as any).image?.fullSize?.childImageSharp?.gatsbyImageData as IGatsbyImageData);

    if (gatsbyImg) {
      const url = gatsbyImg.images?.fallback?.src
      if (url) {
        metaImageUrl = url;
        jsonLdImages.push(shopifyImageToJsonLd({
          url: url,
          height: gatsbyImg.height,
          width: gatsbyImg.width
        }));
      }
    }
  }

  const shopifyProductIdNumber = removeShopifyIdPath(product.shopifyId);

  // let currentDate = new Date();
  // currentDate.setHours(0, 0, 0, 0);
  // currentDate.setDate(currentDate.getDate() + 14);

  // console.log('heree head', location)

  // const defaultVariant = last(product.variants);
  // const selectedVariant = variantId ? product.variants.find(v => v.shopifyId === variantId) : defaultVariant


  // console.log('heree', serverData)
  //   "image": "https://${location.origin}${((firstImage as any).getSrc(image.thumbnail).childImageSharp.gatsbyImageData as IGatsbyImageData).images.fallback.src}",

  return (
    <SeoHead
      title={'Shop ' + title}
      description={seoDescription}
      pathname={location.pathname}
      image={{ url: metaImageUrl || jsonLdImages[0]["url"] }}
    >
      <script type="application/ld+json" id={`product_${shopifyProductIdNumber}`}>
        {
          shopifyProductToJsonLd(product, shopifyAdmin, seoMetadata.site.siteUrl)
        }
      </script>
    </SeoHead>
  )
}

export const query = graphql`
  query ProductDetailPage($handle: String!, $id: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      ...ShopifyProductCard 
    
      seo1x1Image {
        preview {
          image {
            url
            height
            width
          }
        }
      }
      seo4x3Image {
        preview {
          image {
            url
            height
            width
          }
        }
      }
      seo16x9Image {
        preview {
          image {
            url
            height
            width
          }
        }
      }   
    }
    suggestions: allShopifyProduct(
      limit: 3
      # filter: { productType: { eq: $productType }, id: { ne: $id } }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        ...ShopifyProductCard
      }
    }
    shopifyAdmin {
      products(first:1, query: $handle){
        nodes {
          metafields(first:1, namespace: "global") {
            nodes {
              ownerType,
              key,
              namespace,
              value          
            }
          }
        }
      }
    }    
    shopifyStorefront {
      products(first:1, query: $handle){
        nodes {
          id
          metafields(identifiers: [{key: "tabs", namespace: "custom"}, {key: "highlights", namespace: "custom"}]) {
            id
            key
            value
            references(first: 250) {
                nodes {
                  ...on ShopifyStorefront_Metaobject {
                    fields {
                      key
                      value
                    }
                  }
                }
            }
          }
        }
      }
    }
  }

  fragment ShopifyProductCard on ShopifyProduct {
    shopifyId
    storefrontId
    id
    hasOnlyDefaultVariant
    vendor
    descriptionHtml
    description
    tags  
    title
    handle
    productType

    # Used for Shopify analytics
    productGid: shopifyId
    name: title
    brand: vendor
    media {
      ... on ShopifyMediaImage {
        shopifyId
        image {
          altText
          url: src
          height
          width
          fullSize: localFile {
            childImageSharp {
              gatsbyImageData(
                  placeholder: NONE
                  width: 550
                  layout: CONSTRAINED
                  quality: 100
                )
            }
          }  
          thumbnail: localFile {
            childImageSharp {
              gatsbyImageData(
                  placeholder: NONE
                  width: 100
                  height: 100
                  layout: FIXED
                  quality: 100
                )
            }
          }   
        }
      }
    }
    variants {
      id
      title
      shopifyId
      availableForSale
      storefrontId
      price
      sku
      presentmentPrices{
        compareAtPrice {
          amount
          currencyCode
        }
        price {
          amount
          currencyCode
        }
      }
      selectedOptions {
        name
        value
      }
      inventoryQuantity
    }
    options {
      name
      values
      id: shopifyId
    }
    priceRangeV2 {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
  }
`
