// import {
//     useContentfulInspectorMode as useContentfulInspectorModeContentful, useContentfulLiveUpdates as useContentfulLiveUpdatesContentful
// } from '@contentful/live-preview/react';

// export const useContentfulInspectorMode = useContentfulInspectorModeContentful;
// export const useContentfulLiveUpdates = useContentfulLiveUpdatesContentful;

export const useContentfulInspectorMode = () => {
    return (_props: any) => { return {} };
}
export const useContentfulLiveUpdates = (props: any) => props;